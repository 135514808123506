import React from "react";
import * as styled from "./WorkingAtBitwise.styles";

export const WorkingAtBitwise = () => {
  return (
    <>
      <styled.Title id={"values"}>WORKING AT BITWISE</styled.Title>

      <styled.WorkingAtBitwiseContainer>
        <styled.Column>
          <styled.ColumnTitle>We are a remote-first studio.</styled.ColumnTitle>

          <styled.ColumnText>
            That means we do not have a physical office you drive to each
            morning. Instead, our engineers all work from home.
          </styled.ColumnText>

          <styled.ColumnText>
            Usually our engineers are attached to a client's project, for
            example:
          </styled.ColumnText>

          <styled.ColumnText>
            A AAA project for a well known studio, A "gaming adjacent" project,
            like simulation for vehicle automation, A gaming startup building
            their first prototype.
          </styled.ColumnText>

          <styled.ColumnText>
            Most engagements last 3 - 12 months, and therefore engineers will be
            frequently exposed to new projects.
          </styled.ColumnText>
        </styled.Column>

        <styled.Column>
          <styled.ColumnTitle>
            Treating people right is how we build a great team of talented
            developers.
          </styled.ColumnTitle>

          <styled.ColumnText>
            We do not have specific open roles. We focus on hiring outstanding
            people, and then finding the right work for them. If you are
            interested, contact us and we can discuss!
          </styled.ColumnText>

          <styled.ColumnText>
            What we're looking for: Experienced software engineers that can
            teach us what they know. Junior software engineers that seek
            mentorship and focused training. An attitude in line with our
            company values of humility, respect, and leadership.
          </styled.ColumnText>
        </styled.Column>
      </styled.WorkingAtBitwiseContainer>
    </>
  );
};
