import React from "react";
import { Separator } from "../Separator/Separator";
import * as styled from "./WhatWeOffer.styles";

export const WhatWeOffer = () => {
  return (
    <Separator margin={"5% 0 0 0"}>
      <styled.WhatWeOfferContainer>
        <styled.LeftColumn>
          <styled.ColumnTitle>What we offer:</styled.ColumnTitle>
          <styled.ColumnText>
            {`
Above market salary. We know the real value of quality engineering.
High quality health benefits.
Matching 401k with access to low cost index funds (Guideline 401k)
Respect for your time; we do NOT do unpaid crunch. We do not believe crunch is effective.
The opportunity to work on a variety of different projects and technologies.
Work from home. We are a remote-first studio, and nearly all of our work is done from home.
A quality work environment: Good tools, friendly co-workers.



            `}
          </styled.ColumnText>
        </styled.LeftColumn>

        <styled.RightColumn>
          <styled.ColumnTitle>
            A special note to junior engineers:
          </styled.ColumnTitle>

          <styled.ColumnText>
            Landing your first job in the game industry can be frustrating, just
            getting that foot in the door is very difficult. Most employers are
            not willing to make the long-term investment in training new talent.
          </styled.ColumnText>

          <styled.ColumnText>
            We can do better, we want to channel your passion and make you the
            best engineer you can be. We consider your hobby and school
            projects, we test your aptitude and knowledge, and if hired we'll
            give you guidance and mentorship to get your career on the right
            path.
          </styled.ColumnText>
        </styled.RightColumn>
      </styled.WhatWeOfferContainer>
    </Separator>
  );
};
