import * as React from "react";
import "../index.css";
import { PageLayout } from "../components/layout/PageLayout";
import { GetInTouch } from "../components/Separator/GetInTouch";
import { WorkWithUs } from "../components/Separator/WorkWithUs";
import { WorkingAtBitwise } from "../components/WorkingAtBitwise";
import { WhatWeOffer } from "../components/WhatWeOffer/WhatWeOffer";
import { HomeBackground2 } from "../images";
import { OpenPositions } from "../components/OpenPositions";
import { useScrollToSection } from "../hooks";
import { RouteState } from "../types/RouteState";

const IndexPage = ({ location }: RouteState) => {
  useScrollToSection(location.state?.section);
  return (
    <PageLayout>
      <WorkWithUs />
      <OpenPositions />
      <WorkingAtBitwise />
      <WhatWeOffer />
      <GetInTouch margin={"0 0"} backgroundImage={HomeBackground2} />
    </PageLayout>
  );
};

export default IndexPage;
