import React, { ReactNode } from "react";
import { SpinnerContainer } from "./spinner.styles";

type WithSpinnerProps = {
  children?: any;
};

export const WithSpinner = ({ children }: WithSpinnerProps) => {
  return children ?? <SpinnerContainer />;
};
