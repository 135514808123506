import React from "react";
import { Separator } from "./Separator";
import { CTAButton } from "../button/Button";
import styled from "styled-components";
import { uiStore } from "../../stores";

const Title = styled.h2`
  font-size: 60px;
  font-family: "Poppins", serif;
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.667;
  margin: 0;

  @media (max-width: 1000px) {
    font-size: 40px;
  }
`;

const Subtitle = styled.p`
  font-size: 30px;
  font-family: "Poppins", serif;
  color: rgb(255, 255, 255);
  line-height: 1.667;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  padding: 0% 10%;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 10px;
    height: 400px;
    justify-content: center;
    align-items: center;
  }
`;

export const WorkWithUs = () => {
  return (
    <Separator margin={"0% 0%"}>
      <Container>
        <Title>WORK WITH US</Title>
        <Subtitle>WORKING AT BITWISE ALCHEMY</Subtitle>
        <CTAButton as={"a"}  href={"mailto:jobs@bitwisealchemy.com"}>
          SEND YOUR RESUME
        </CTAButton>
      </Container>
    </Separator>
  );
};
