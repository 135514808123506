import styled from "styled-components";

export const OpenPositionsOuterContainer = styled.div`
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const OpenPositionsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 3%;

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0;
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled.h2`
  margin-left: 10%;
  font-size: 60px;
  font-family: "Poppins", sans-serif;
  color: rgb(38, 146, 255);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.667;
  text-align: left;
  margin-bottom: 3%;

  @media (max-width: 1000px) {
    font-size: 40px;
    text-align: center;
    margin-left: 0;
    margin-top: 10%;
  }
`;

export const OpenPosition = styled.div`
  width: 33%;
  min-height: 200px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 13px 23.68px 8.32px rgba(0, 0, 0, 0.15);

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`;

export const OpenPositionTitle = styled.h4`
  font-size: 25px;
  font-family: "Poppins";
  color: rgb(38, 146, 255);
  font-weight: bold;
  line-height: 2;
  margin: 0;
`;

export const OpenPositionRole = styled.a`
  font-size: 22px;
  font-family: "Poppins";
  line-height: 2;
  margin: 0;
  font-weight: unset;
  text-decoration: none;
  color: unset;
`;

export const OpenPositionLocation = styled.span`
  font-size: 14px;
  font-family: "Poppins";
  line-height: 2;
  margin: 0;
`;
