import styled from "styled-components";

export const Title = styled.h2`
  margin-left: 10%;
  font-size: 60px;
  font-family: "Poppins", sans-serif;
  color: rgb(38, 146, 255);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.667;
  text-align: left;
  margin-bottom: 0;

  @media (max-width: 1000px) {
    font-size: 40px;
    text-align: center;
    margin-left: 0;
    margin-top: 10%;
  }
`;

export const WorkingAtBitwiseContainer = styled.div`
  display: flex;
  width: 80%;
  margin-left: 10%;
  justify-content: space-between;

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0;
    flex-direction: column;
    padding: 20px;
    text-align: center;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0;
    flex-direction: column;
  }
`;

export const ColumnTitle = styled.h3`
  font-size: 30px;
  font-family: "Poppins";
  color: rgb(47, 69, 92);
  line-height: 1.667;

  @media (max-width: 1000px) {
    font-size: 32px;
    margin: 0;
    text-align: center;
  }
`;

export const ColumnText = styled.p`
  font-size: 20px;
  font-family: "Poppins";
  color: rgb(47, 69, 92);
  line-height: 1.667;

  @media (max-width: 1000px) {
    font-size: 22px;
  }
`;
