import React from "react";
import * as styled from "./OpenPositions.styles";
import { CTAButton } from "../button";
import { OpenPositionLocation } from "./OpenPositions.styles";
import { useBambooJobs } from "../../hooks";
import { WithSpinner } from "../spinner";

export const OpenPositions = () => {
  const jobs = useBambooJobs();
  return (
    <>
      <styled.Title>OPEN POSITIONS</styled.Title>

      <styled.OpenPositionsOuterContainer>
        <styled.OpenPositionsContainer>
          <WithSpinner>
            {jobs?.map((j) => (
              <styled.OpenPosition>
                <styled.OpenPositionTitle>
                  {j.department.toUpperCase()}
                </styled.OpenPositionTitle>
                <styled.OpenPositionRole href={j.url} target="_blank">
                  {j.title}
                </styled.OpenPositionRole>
                <styled.OpenPositionLocation>
                  {j.location}
                </styled.OpenPositionLocation>
              </styled.OpenPosition>
            ))}
          </WithSpinner>
        </styled.OpenPositionsContainer>
        <CTAButton
          as={"a"}
          href={"https://bitwisealchemy.bamboohr.com/jobs/"}
          target={"_blank"}
        >
          VIEW MORE
        </CTAButton>
      </styled.OpenPositionsOuterContainer>
    </>
  );
};
