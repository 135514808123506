import styled from "styled-components";

export const WhatWeOfferContainer = styled.div`
  width: 80%;
  margin-left: 10%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0;
    flex-direction: column;
  }
`;

export const LeftColumn = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  color: white;

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0;
    flex-direction: column;
  }
`;

export const ColumnTitle = styled.h3`
  font-size: 30px;
  font-family: "Poppins";
  line-height: 1.267;
  margin-bottom: 0;
`;

export const ColumnText = styled.p`
  font-size: 20px;
  font-family: "Poppins";
  line-height: 1.767;
  white-space: pre-wrap;

  @media (max-width: 1000px) {
    line-height: 2.5;
    font-size: 25px;
  }
`;

export const RightColumn = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 13px 23.68px 8.32px rgba(0, 0, 0, 0.15);
  color: rgb(47, 69, 92);

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0;
    flex-direction: column;
  }
`;
